<!--
 * @Author: your name
 * @Date: 2020-12-15 18:08:03
 * @LastEditTime: 2021-03-11 17:58:58
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \tostumid:\project\web\diaoke\vue-admin-template\src\views\agreement\index.vue
-->
<template>
  <div class="box">
    <!-- 中文 -->
    <div v-if="lang == 'CN'">
      <h1>拾音APP隐私协议</h1>
      <h2>欢迎您使用拾音助手APP！</h2>

      <p>
        为使用拾音助手APP及服务（以下简称“本服务”），您应当阅读并遵守《拾音APP用户隐私协议》（以下简称“本协议”）协议和业务规则。请您务必审慎阅读、充分理解各条款内容，特别是限制或免除责任条款，以及开通或使用某项服务的单独协议。限制或免除责任条款可能以黑体加粗形式提示您注意。
      </p>

      <p>
        除非您已阅读并接受本协议所有条款，否则您无权使用本服务。您对本服务的授权、查看、下载、连接蓝牙设备、使用等行为即视为您已阅读并同意受本协议的约束。
      </p>

      <p>
        如果您未满18周岁，请在法定监护人的陪同下阅读本协议，并特别注意未成年人使用条款。
      </p>

      <p>
        一、【协议的范围】
      </p>

      <p>
        1.1
        本协议是您与广州华汇科技有限公司之间关于您下载、安装拾音助手APP及使用拾音APP服务所订立的协议。“用户”是指使用拾音APP服务的使用人，在本协议中更多地称为“您”。
      </p>

      <p>
        1.2
        本协议内容同时包括广州华汇可能不断发布的关于本服务的相关协议、业务规则等内容；您在使用广州华汇公司或其合作单位某一特定服务时，可能会另有单独的协议、相关业务规则等。上述内容一经正式发布，即为本协议不可分割的组成部分，您同样应当遵守。您对前述任何业务规则、单独协议的接受，即视为您对本协议全部的接受。
      </p>

      <p>
        二、【本服务内容】
      </p>

      <p>
        2.1
        本服务内容是指广州华汇公司向用户提供的使用拾音助手APP进行蓝牙连接麦克风设备功能，以及包括开启外设麦克风收音服务（以下简称“本服务”）。广州华汇公司可以对其提供的服务内容予以变更，并对用户进行相应的提示。
      </p>

      <p>
        2.2
        您使用本服务需要下载及安装拾音助手客户端软件（以下简称“本软件”），您可以直接从广州华汇的网站上获取本软件，也可以从得到广州华汇授权的第三方平台获取。下载安装程序后，您需要按照该程序提示的步骤正确安装。
      </p>

      <p>
        2.3 本软件提供的服务为免费服务。
      </p>

      <p>
        三、【软件许可】
      </p>

      <p>
        3.1
        对于本软件，广州华汇给予您一项个人的、不可转让及非排他性的许可，您可以为非商业目的在单一台终端设备上安装、使用、显示、运行本软件。本协议其他条款未明示授权的其他一切权利仍由广州华汇保留，您在行使这些权利时须另外取得广州华汇的书面许可。广州华汇如果未行使前述任何权利，并不构成对该权利的放弃。
      </p>

      <p>
        3.2
        如果您从未经广州华汇授权的第三方获取本软件或与本软件名称相同的安装程序，广州华汇无法保证该软件能够正常使用，并对因此给您造成的损失不予负责。
      </p>

      <p>
        3.3
        广州华汇可能为不同的终端设备开发了不同的软件版本，您应当根据实际情况选择下载合适的版本进行安装。
      </p>

      <p>
        3.4
        如果您不再需要使用本软件或者需要安装新版软件，可以自行卸载。如果您愿意帮助广州华汇改进产品服务，请告知卸载的原因。
      </p>

      <p>
        3.5
        为了改善用户体验、完善服务内容，广州华汇将不断努力开发新的服务，并为您不时提供软件更新（这些更新可能会采取软件替换、修改、功能强化、版本升级等形式）。本软件新版本发布后，旧版本的软件可能无法使用。广州华汇不保证旧版本软件继续可用及提供相应的客户服务，请您随时核对并下载最新版本。
      </p>

      <p>
        3.6
        除非法律允许或广州华汇公司书面许可，您不得从事下列行为，否则广州华汇公司将保留依法追究法律责任的权利：
      </p>

      <p>
        3.6.1 删除本软件及其副本上关于著作权的信息；
      </p>

      <p>
        3.6.2
        对本软件进行反向工程、反向汇编、反向编译，或者以其他方式尝试发现本软件的源代码；
      </p>

      <p>
        3.6.3
        对广州华汇拥有知识产权的内容进行使用、出租、出借、复制、修改、链接、转载、汇编、发表、出版、建立镜像站点等，擅自借助本软件及相关信息发展与之有关的衍生产品、作品、服务、插件、外挂、兼容、互联等；
      </p>

      <p>
        3.6.4
        对本软件或者本软件运行过程中释放到任何终端内存中的数据、软件运行过程中客户端与服务器端的交互数据，以及本软件运行所必需的系统数据，进行复制、修改、增加、删除、挂接运行或创作任何衍生作品，形式包括但不限于使用插件、外挂或非广州华汇经授权的第三方工具/服务接入本软件和相关系统；
      </p>

      <p>
        3.6.5
        通过修改或伪造软件运行中的指令、数据，增加、删减、变动软件的功能或运行效果，或者将用于上述用途的软件、方法进行运营或向公众传播，无论这些行为是否为商业目的；
      </p>

      <p>
        3.6.6
        通过非广州华汇开发、授权的第三方软件、插件、外挂、系统，登录或使用广州华汇软件及服务，或制作、发布、传播上述工具；
      </p>

      <p>
        3.6.7
        自行或者授权他人、第三方软件对本软件及其组件、模块、数据进行干扰，或者通过修改或伪造软件运行中的指令、数据、数据包，增加、删减、变动软件的功能或运行效果，及将用于上述用途的软件通过信息网络向公众传播或者运营；
      </p>

      <p>
        3.6.8
        其他未经广州华汇明示授权的行为以及以任何不合法的方式、为任何不合法的目的、或以任何与本协议不一致的方式使用本软件。
      </p>

      <p>
        3.7
        本软件可能会使用第三方软件或技术（包括本软件可能使用的开源代码和公共领域代码等，下同），这种使用已经获得合法授权。
      </p>

      <p>
        3.8
        本软件如果使用了第三方的软件或技术，广州华汇公司将按照相关法规或约定，对相关的协议或其他文件，可能通过本协议附件、在本软件安装包特定文件夹中打包以及其他形式进行展示，它们可能会以“软件使用许可协议”、“授权协议”、“开源代码许可证”或其他形式来表达。前述通过各种形式展现的相关协议或其他文件，均是本协议不可分割的组成部分，与本协议具有同等的法律效力，您应当遵守这些要求。如果您没有遵守这些要求，该第三方或者国家机关可能会对您提起诉讼、罚款或采取其他制裁措施，并要求广州华汇公司给予协助，您应当自行承担法律责任。
      </p>

      <p>
        3.9
        如因本软件使用的第三方软件或技术引发的任何纠纷，应由该第三方负责解决，广州华汇公司不承担任何责任。广州华汇公司不对第三方软件或技术提供客服支持，若您需要获取支持，请与第三方联系。
      </p>

      <p>
        四、【帐号安全与用户个人信息保护】
      </p>

      <p>
        4.1 您在使用本服务时无需注册，可直接登录
      </p>

      <p>
        4.2 广州华汇公司特别提醒当您使用完毕后，应安全退出。
      </p>

      <p>
        4.3
        保护用户个人信息是广州华汇公司的一项基本原则，广州华汇将采取技术措施和其他必要措施，确保用户个人信息安全，并承诺不在本服务中收集的用户个人信息。在发生前述情形或者广州华汇公司发现存在发生前述情形的可能时，广州华汇公司将及时采取补救措施并告知用户，用户如发现存在前述情形亦需立即与广州华汇公司联系。
      </p>

      <p>
        4.4
        您在使用本软件或本服务的过程中，若国家法律法规有特殊规定的，您需要填写真实的身份信息。若您填写的信息不完整或不符合法律法规的规定，则无法使用本服务或在使用过程中受到限制。
      </p>

      <p>
        4.5
        广州华汇非常重视对未成年人个人信息的保护。若您是18周岁以下的未成年人，在使用广州华汇的服务前，应事先取得您家长或法定监护人的书面同意。
      </p>

      <p>
        4.6 除本协议另有约定外，该隐私保护指引为本协议的组成部分。
      </p>

      <p>
        五、【用户行为规范】
      </p>

      <p>
        5.1
        您可使用本服务开启外设麦克风收音功能。您必须保证，您拥有设备及通过设备产生的内容的知识产权或已获得合法授权，您使用本软件及服务的任何行为未侵犯任何第三方之合法权益。
      </p>

      <p>
        5.2 您在使用本服务时不得利用本服务从事以下行为，包括但不限于：
      </p>

      <p>
        5.2.1
        发布、传送、传播、储存违反国家法律、危害国家安全统一、社会稳定、公序良俗、社会公德以及侮辱、诽谤、淫秽或含有任何性或性暗示的、暴力的内容；
      </p>

      <p>
        5.2.2
        发布、传送、传播、储存侵害他人名誉权、肖像权、知识产权、商业秘密等合法权利的内容；
      </p>

      <p>
        5.2.3 发布、传送、传播、储存涉及他人隐私、个人信息、财产、保密信息的；
      </p>

      <p>
        5.2.4 发表、传送、传播骚扰、广告信息及垃圾信息；
      </p>

      <p>
        5.2.5
        未经广州华汇公司书面同意，擅自将本软件或服务应用于商业用途或进行营利活动；
      </p>

      <p>
        5.2.6 虚构事实、隐瞒真相以误导、欺骗他人；
      </p>

      <p>
        5.2.7
        其他违反法律法规、政策及公序良俗、社会公德或干扰QQ音乐正常运营和侵犯其他用户或第三方合法权益内容的信息。
      </p>

      <p>
        5.3
        您充分了解并同意，您必须为自己使用本软件及服务的一切行为负责，包括您所发表的任何内容以及由此产生的任何后果。您应对本服务中的内容自行加以判断，并承担因使用内容而引起的所有风险，包括因对内容的正确性、完整性或实用性的依赖而产生的风险。广州华汇无法且不会对因前述风险而导致的任何损失或损害承担责任。
      </p>

      <p>
        5.4
        您同意广州华汇公司可为履行本协议或提供本服务的目的而使用您发送或传播的内容。
      </p>

      <p>
        5.5
        您应当理解，您使用本软件及服务需自行准备与相关服务有关的终端设备（如电脑、移动设备等），并承担所需的费用（如网络宽带费等）。
      </p>

      <p>
        5.6
        您在使用本软件某一特定服务时，该服务可能会另有单独的协议或相关业务规则等（以下统称为“单独协议”），您在使用该项服务前请阅读并同意相关的单独协议。若该服务由第三方提供，则广州华汇公司和第三方对可能出现的纠纷在法律规定和约定的范围内各自承担责任。
      </p>

      <p>
        5.7
        用户在使用本软件及服务时，须自行承担如下不可掌控的风险内容，包括但不限于：
      </p>

      <p>
        5.7.1 由于不可抗拒因素可能引起的个人信息丢失、泄漏等风险；
      </p>

      <p>
        5.7.2
        用户必须选择与所安装设备相匹配的软件版本，否则，由于软件与设备不相匹配所导致的任何问题或损害，均由用户自行承担；
      </p>

      <p>
        5.8.3
        用户在使用本软件访问第三方网站时，因第三方网站及相关内容所可能导致的风险，由用户自行承担；
      </p>

      <p>
        5.8.4
        用户通过拾音助手传播的内容被他人转发、分享，因此等传播可能带来的风险和责任；
      </p>

      <p>
        5.9
        您在使用本服务过程中应当遵守当地相关的法律法规，并尊重当地的道德和风俗习惯。如果您的行为违反了当地法律法规或道德风俗，您应当为此独立承担责任。
      </p>

      <p>
        5.10
        您应避免因使用本服务而使广州华汇卷入政治和公共事件，否则广州华汇公司有权暂停或终止对您的服务。
      </p>

      <p>
        六、【知识产权声明】
      </p>

      <p>
        6.1
        广州华汇是本软件的知识产权权利人，除本服务中涉及广告的知识产权由相应广告商享有外，广州华汇在本服务中提供的内容（包括但不限于网页、文字、图片、音频、视频、图表等）的知识产权归广州华汇公司所有。
      </p>

      <p>
        6.2
        除另有特别声明外，广州华汇公司提供本服务时所依托软件的著作权、专利权及其他知识产权均归广州华汇公司所有。
      </p>

      <p>
        6.3
        广州华汇公司在本服务中所使用的图形和商业标识，其著作权或商标权归广州华汇公司所有。未经广州华汇事先书面同意，用户不得将以上标识以任何方式展示或使用或作其他处理，也不得向他人表明用户有权展示、使用、或其他有权处理广州华汇标识的行为。
      </p>

      <p>
        6.4
        上述及其他任何广州华汇公司或相关广告商依法拥有的知识产权均受到法律保护，未经广州华汇公司或相关广告商书面许可，用户不得以任何形式进行使用或创造相关衍生作品。
      </p>

      <p>
        6.5
        用户在使用传音助手服务时发表音频以及表演等信息，此部分信息的知识产权归用户，责任由用户承担，但用户的发表、上传行为是对广州华汇公司的授权，用户确认将其上传、发表的信息非独占性、永久性地授权给广州华汇公司于全球范围内可以使用、复制、传播、修改、汇编、改编、再许可、翻译、创建衍生作品、出版、表演及展示此等内容。
      </p>

      <p>七、【法律责任】</p>

      <p>
        7.1
        如果广州华汇公司发现或收到他人举报或投诉用户发布的信息内容或行为违反本协议约定的，广州华汇公司有权不经通知随时对相关内容进行独立判断，并采取技术手段予以删除、屏蔽或断开链接，并视情节轻重采取包括但不限于暂停或终止本服务、进行处罚（本协议处罚均包括但不限于警告、功能封禁、设备封禁等）、追究法律责任等措施。
      </p>

      <p>
        7.2
        用户理解并同意，广州华汇公司有权依合理判断对违反有关法律法规或本协议规定的行为进行处罚，对违法违规的任何用户采取适当的法律行动，并根据法律规定或相关政府机关、安全机关、司法机关的要求提供用户的个人信息、保存有关信息向有关部门报告等，用户应承担由此而产生的一切法律责任。
      </p>

      <p>
        7.3
        用户理解并同意，因用户违反本协议约定，导致或产生的任何第三方主张的任何索赔、要求或损失，包括合理的律师费，用户应当赔偿广州华汇与合作公司、关联公司，并使之免受损害。
      </p>

      <p>八、【不可抗力及其他免责事由】</p>

      <p>
        8.1
        在使用本服务的过程中，可能会遇到不可抗力等风险因素，使本服务发生中断。不可抗力是指不能预见、不能克服并不能避免且对一方或双方造成重大影响的客观事件，包括但不限于自然灾害如洪水、地震、瘟疫流行和风暴等以及社会事件如战争、动乱、政府行为等。出现上述情况时，广州华汇公司将努力在第一时间与相关单位配合，及时进行修复，但是由此给您造成的损失广州华汇公司在法律允许的范围内免责。
      </p>

      <p>
        8.2
        您理解并同意，本服务是按照现有技术和条件所能达到的现状提供的。广州华汇公司会尽最大努力向您提供服务，确保服务的连贯性和安全性；但广州华汇公司不能随时预见和防范法律、技术以及其他风险，包括但不限于不可抗力、病毒、木马、黑客攻击、系统不稳定、第三方服务瑕疵、政府行为等原因可能导致的服务中断、数据丢失以及其他的损失和风险。
      </p>

      <p>
        8.3
        您理解并同意：广州华汇公司向您提供的服务，可能包含广州华汇公司经合法权利人授权而向用户提供的相关服务，因此，由于该权利人对广州华汇公司授权时间、范围、限制等的约束，所以，广州华汇公司会对开通相关服务用户所在的地区以及用户具体使用服务的时间、地域范围、终端设备等做一定限制，可能会导致用户在一定的地区或时间、不同的终端设备等情况下无法获得相关服务，包括但不限于目前相关服务仅限用户在中国大陆地区（不含香港、澳门、台湾等地区）范围内开通并使用相关服务，如果您在中国大陆地区之外或者使用的是非中国大陆地区之网络/设备等，则无法使用/获得相关服务。对于因为前述原因或其他原因造成用户无法享有相关服务的，广州华汇公司无需承担任何责任。
      </p>

      <p>
        8.4
        您理解并同意：广州华汇公司向您提供的服务或内容，可能会根据法律法规或监管政策的变化、权利人或版权方的要求、执法部门或司法机关的要求、其他第三方的投诉等进行变更或中断、中止或终止。对于因为前述原因或其他原因造成用户无法享有相关服务或内容的，广州华汇公司无需承担任何责任。
      </p>

      <p>
        8.5
        您理解并同意，在使用本服务的过程中，可能会遇到网络信息或其他用户行为带来的风险，广州华汇公司不对任何信息的真实性、适用性、合法性承担责任，也不对因侵权行为给您造成的损害负责。这些风险包括但不限于：
      </p>

      <p>
        8.5.1
        因使用本协议项下的服务，遭受他人误导、欺骗或其他导致或可能导致的任何心理、生理上的伤害以及经济上的损失；
      </p>

      <p>
        8.5.2 其他因网络信息或用户行为引起的风险。
      </p>

      <p>
        8.6
        广州华汇公司依据本协议约定获得处理违法违规内容的权利，该权利不构成广州华汇公司的义务或承诺，广州华汇公司不能保证及时发现违法行为或进行相应处理。
      </p>

      <p>
        8.7
        在任何情况下，您不应轻信借款、索要密码或其他涉及财产的网络信息。涉及财产操作的，请一定先核实对方身份，并请经常留意广州华汇公司有关防范诈骗犯罪的提示。
      </p>

      <p>九、【服务的变更、中断、终止】</p>

      <p>
        9.1
        广州华汇公司始终在不断变更和改进服务。广州华汇公司根据自身商业决策、政府行为、不可抗力等原因可能会选择中止、中断及终止拾音助手的部分或全部服务。如有此等情形发生，广州华汇公司会采取公告的形式通知用户，但不承担由此对用户造成的任何损失。
      </p>

      <p>
        9.2
        如发生下列任何一种情形，广州华汇公司有权不经通知而中断或终止向您提供的服务：
      </p>

      <p>9.2.1 您违反相关法律法规或本协议的约定；</p>

      <p>9.2.2 按照法律规定或主管部门的要求；</p>

      <p>9.2.3 出于安全的原因或其他必要的情形。</p>
      <p></p>
      <p>
        十、【协议的生效和变更】
      </p>

      <p>
        10.1 您使用拾音助手软件或服务即视为您已阅读本协议并接受本协议的约束。
      </p>

      <p>
        10.2
        广州华汇公司有权在必要时修改本协议条款。您可以在相关服务页面查阅最新版本的协议条款，并自觉遵守本协议条款的内容。
      </p>

      <p>
        10.3
        本协议条款变更后，如果您继续使用本服务，即视为您已接受修改后的协议。如果您不接受修改后的协议，应当停止使用本服务。
      </p>

      <p>十一、【管辖与法律适用】</p>

      <p>
        11.1 本协议签订地为中华人民共和国广东省广州市海珠区。
      </p>

      <p>
        11.2
        本协议的成立、生效、履行、解释及纠纷解决，适用中华人民共和国大陆地区法律（不包括冲突法）。
      </p>

      <p>
        11.3
        若您和广州华汇公司之间发生任何纠纷或争议，首先应友好协商解决；协商不成的，您同意将纠纷或争议提交本协议签订地有管辖权的人民法院管辖。
      </p>

      <p>十二、【未成年人使用条款】</p>

      <p>
        12.1
        若用户未满18周岁，则为未成年人，应在监护人监护、指导下阅读本协议和使用本服务。
      </p>

      <p>
        12.2
        未成年人用户涉世未深，容易被网络虚象迷惑，且好奇心强，遇事缺乏随机应变的处理能力，很容易被别有用心的人利用而又缺乏自我保护能力。因此，未成年人用户在使用本服务时应注意以下事项，提高安全意识，加强自我保护：
      </p>

      <p>
        12.2.1
        认清网络世界与现实世界的区别，避免沉迷于网络，影响日常的学习生活；
      </p>
      <p></p>
      <p>
        12.2.2
        填写个人资料时，加强个人保护意识，以免不良分子对个人生活造成骚扰；
      </p>

      <p>
        13.2.3 在监护人或老师的指导下，学习正确使用网络；
      </p>

      <p>
        12.2.4
        避免陌生网友随意会面或参与联谊活动，以免不法分子有机可乘，危及自身安全。
      </p>

      <p>
        12.3
        监护人、学校均应对未成年人使用本服务时多做引导。特别是家长应关心子女的成长，注意与子女的沟通，指导子女上网应该注意的安全问题，防患于未然。监护人或家长对未成年人上网行为缺乏有效监管方式的，可加强对未成年人上网行为的监护，引导未成年人健康、和谐的使用本服务。
      </p>

      <p>
        12.4
        未成年人在无监护人监护、指导或监护人未尽到监护、指导义务的情况下，自行阅读本协议和使用本服务，由此对未成年人本人或其家庭或他人带来的不利后果或损失，均由该未成年人及其家长或监护人承担，广州华汇公司不对未成年人该行为产生的后果或损失承担任何责任。
      </p>

      <p>十三、【其他】</p>

      <p>
        13.1
        本协议所有条款的标题仅为阅读方便，本身并无实际涵义，不能作为本协议涵义解释的依据。
      </p>

      <p>
        13.2
        本协议条款无论因何种原因部分无效或不可执行，其余条款仍有效，对双方具有约束力。
      </p>

      <p>
        13.3
        如果您对本协议或本服务有意见或建议，可与广州华汇客户服务部门，我们会给予您必要的帮助。
      </p>

      <p style=";text-align:right">
        广州华汇信息科技有限公司
      </p>

      <p>
        <br />
      </p>
    </div>
    <!-- 英文 -->
    <div v-if="lang == 'EN'">
      <h1>Recording APP Privacy Agreement</h1>
      <h2>Welcome to the Recording Picker App!</h2>

      <p></p>
      <p>
        In order to use the Recording Assistant App and Services (the
        &quot;Service&quot;), you should read and comply with the Recording
        Assistant User Privacy Agreement (the &quot;Agreement&quot;) agreement
        and business rules. Please be sure to read and fully understand the
        terms and conditions, especially the limitation or exclusion of
        liability clause, and the separate agreement for opening or using a
        particular service. Limitations or exclusions of liability may be bolded
        and highlighted for your attention.
      </p>
      <p></p>
      <p>
        You are not authorized to use the Service unless you have read and
        accepted all of the terms of this Agreement. You are deemed to have read
        and agreed to be bound by this Agreement by authorizing, viewing,
        downloading, connecting to Bluetooth devices, and using the Service.
      </p>
      <p></p>
      <p>
        If you are under 18 years of age, please read this Agreement with a
        legal guardian and pay special attention to the terms of use for minors.
      </p>
      <p></p>
      <p>
        I. [Scope of the Agreement]
      </p>
      <p></p>
      <p>
        1.1 This Agreement is an agreement between you and Guangzhou HuaHui
        Technology Co., Ltd. regarding your downloading and installation of the
        Recording Assistant APP and use of the Recording APP service. The term
        &quot;User&quot; refers to the user of the Recording APP service, more
        commonly referred to as &quot;You&quot; in this Agreement.
      </p>
      <p></p>
      <p>
        1.2 The content of this Agreement also includes the relevant agreements,
        business rules, etc. that Guangzhou HuaHui may continuously release
        about the Service; there may be separate agreements, relevant business
        rules, etc. when you use a specific service of Guangzhou HuaHui or its
        partners. Once the above content is officially released, it is an
        integral part of this agreement, and you should also comply with it.
        Your acceptance of any of the aforementioned business rules and separate
        agreements shall be deemed to be your acceptance of this Agreement in
        its entirety.
      </p>
      <p></p>
      <p>
        Ⅱ. [the service content]
      </p>
      <p></p>
      <p>
        2.1 This service refers to the use of Recording Assistant APP to provide
        users with Bluetooth connection microphone device function, as well as
        including the opening of peripheral microphone radio service
        (hereinafter referred to as &quot;the service&quot;). Guangzhou HuaHui
        company can change the content of the services it provides, and the
        corresponding tips to the user.
      </p>
      <p></p>
      <p>
        2.2 You need to download and install the Recording Assistant client
        software (hereinafter referred to as the &quot;Software&quot;) to use
        the Service, you can obtain the Software directly from the website of
        Guangzhou HuaHui, or from the third party platform authorized by
        Guangzhou HuaHui. After downloading the installation program, you need
        to follow the steps prompted by the program to install it correctly.
      </p>
      <p></p>
      <p>
        2.3 The services provided by the Software are free of charge.
      </p>
      <p></p>
      <p>
        Ⅲ, [software license]
      </p>
      <p></p>
      <p>
        3.1 For the Software, Guangzhou HuaHui gives you a personal,
        non-transferable and non-exclusive license, you can install, use,
        display and run the Software on a single terminal device for
        non-commercial purposes. All other rights not expressly authorized by
        other provisions of this Agreement shall remain with Guangzhou HuaHui,
        and you shall obtain separate written permission from Guangzhou HuaHui
        to exercise such rights. The failure of Guangzhou HuaHui to exercise any
        of the foregoing rights does not constitute a waiver of such rights.
      </p>
      <p></p>
      <p>
        3.2 If you obtain the Software or an installation program with the same
        name as the Software from a third party not authorized by Guangzhou
        HuaHui, Guangzhou HuaHui cannot guarantee that the Software can be used
        normally and will not be responsible for any damage caused to you as a
        result.
      </p>
      <p></p>
      <p>
        3.3 Guangzhou HuaHui may have developed different software versions for
        different terminal devices, you should choose to download the
        appropriate version for installation according to the actual situation.
      </p>
      <p></p>
      <p>
        3.4 If you no longer need to use the software or need to install a new
        version of the software, you can uninstall it yourself. If you are
        willing to help Guangzhou HuaHui improve the product service, please
        inform the reason for uninstallation.
      </p>
      <p></p>
      <p>
        3.5 In order to improve the user experience and improve the service
        content, Guangzhou HuaHui will constantly strive to develop new services
        and provide you with software updates from time to time (these updates
        may take the form of software replacement, modification, function
        enhancement, version upgrade, etc.). After the release of the new
        version of this software, the old version of the software may not be
        used. Guangzhou HuaHui does not guarantee that the old version of the
        software will continue to be available and provide the corresponding
        customer service, please check and download the latest version at any
        time.
      </p>
      <p></p>
      <p>
        3.6 Unless permitted by law or the written permission of Guangzhou
        HuaHui, you shall not engage in the following acts, otherwise Guangzhou
        HuaHui will retain the right to pursue legal responsibility according to
        law.
      </p>
      <p></p>
      <p>
        3.6.1 Delete the information on the Software and its copies regarding
        the copyright.
      </p>
      <p></p>
      <p>
        3.6.2 reverse engineering, reverse assembly, reverse compilation of the
        Software, or otherwise attempting to discover the source code of the
        Software.
      </p>
      <p></p>
      <p>
        3.6.3 to use, rent, lend, copy, modify, link, reproduce, compile,
        publish, publish, establish a mirror site, etc. the content of the
        intellectual property rights owned by Guangzhou HuaHui, and develop
        derivative products, works, services, plug-ins, plug-ins, compatibility,
        interconnection, etc. related to the Software and related information
        without authorization
      </p>
      <p></p>
      <p>
        3.6.4 the software or the data released into the memory of any terminal
        during the operation of the software, the interaction data between the
        client and the server during the operation of the software, and the
        system data necessary for the operation of the software, to copy,
        modify, add, delete, hook up to run or create any derivative works,
        including but not limited to the use of plug-ins, plug-ins or
        non-Guangzhou HuaHui authorized third-party tools/services to access the
        software and related systems. services to access the Software and
        related systems.
      </p>
      <p></p>
      <p>
        3.6.5 by modifying or falsifying the instructions and data in the
        operation of the Software, adding, deleting, changing the functions or
        operating effects of the Software, or operating or disseminating the
        Software or methods used for the above purposes to the public, whether
        or not these acts are for commercial purposes.
      </p>
      <p></p>
      <p>
        3.6.6 through the third party software, plug-ins, plug-ins, systems that
        are not developed or authorized by Guangzhou HuaHui, login or use the
        software and services of Guangzhou HuaHui, or produce, publish or
        disseminate the above tools.
      </p>
      <p></p>
      <p>
        3.6.7 interfere with the software and its components, modules, data by
        itself or authorize others, third party software, or add, delete, change
        the function or operation effect of the software by modifying or
        falsifying the instructions, data, data package in the operation of the
        software, and disseminate or operate the software for the above purposes
        to the public through the information network.
      </p>
      <p></p>
      <p>
        3.6.8 other acts not expressly authorized by Guangzhou HuaHui and the
        use of the Software in any unlawful manner, for any unlawful purpose, or
        in any manner inconsistent with this Agreement.
      </p>
      <p></p>
      <p>
        3.7 The Software may use third party software or technology (including
        open source code and public domain code that may be used in the
        Software, etc., the same below), and such use has been legally
        authorized.
      </p>
      <p></p>
      <p>
        3.8 If the Software uses third-party software or technology, Guangzhou
        HuaHui will, in accordance with relevant regulations or agreements,
        display the relevant agreements or other documents, which may be
        attached to this Agreement, packaged in specific folders of the Software
        installation package, and in other forms, which may be displayed as
        &quot;Software License Agreement&quot;, &quot;License Agreement&quot;,
        &quot;License Agreement&quot;, &quot;License Agreement&quot;,
        &quot;License Agreement&quot;, &quot;License Agreement&quot;, and
        &quot;License Agreement&quot;. &quot;License Agreement&quot;, &quot;Open
        Source License&quot;, or otherwise. The foregoing agreements or other
        documents in any form are an integral part of this Agreement and have
        the same legal effect as this Agreement, and you shall comply with these
        requirements. If you do not comply with these requirements, the third
        party or state authorities may bring a lawsuit against you, fines or
        other sanctions, and require the assistance of Guangzhou HuaHui, you
        shall bear the legal responsibility.
      </p>
      <p></p>
      <p>
        3.9 If any dispute arises from the third-party software or technology
        used in the Software, the third party shall be responsible for the
        settlement, and Guangzhou HuaHui shall not bear any responsibility.
        Guangzhou HuaHui company does not provide customer service support to
        the third-party software or technology, if you need to get support,
        please contact the third party.
      </p>
      <p></p>
      <p>
        IV. [Account Security and Protection of Users&#39; Personal Information]
      </p>
      <p></p>
      <p>
        4.1 You do not need to register when using the service, you can directly
        log in
      </p>
      <p></p>
      <p>
        4.2 Guangzhou HuaHui company specially reminded that when you have
        finished using, you should safely exit.
      </p>
      <p></p>
      <p>
        4.3 Protection of user personal information is a basic principle of
        Guangzhou HuaHui company, Guangzhou HuaHui will take technical measures
        and other necessary measures to ensure the security of user personal
        information, and commitment not to the user personal information
        collected in the service. In the event of the aforementioned situation
        or Guangzhou HuaHui company found that there is the possibility of the
        aforementioned situation, Guangzhou HuaHui company will promptly take
        remedial measures and inform the user, the user found that the
        aforementioned situation also need to immediately contact with Guangzhou
        HuaHui company.
      </p>
      <p></p>
      <p>
        4.4 You use the software or the process of the service, if the national
        laws and regulations have special provisions, you need to fill in the
        real identity information. If the information you fill in is incomplete
        or does not comply with the provisions of laws and regulations, you will
        not be able to use the service or be restricted in the process of use.
      </p>
      <p></p>
      <p>
        4.5 Guangzhou HuaHui attaches great importance to the protection of
        personal information of minors. If you are a minor under the age of 18,
        you should obtain the prior written consent of your parent or legal
        guardian before using the services of Guangzhou HuaHui.
      </p>
      <p></p>
      <p>
        4.6 Unless otherwise agreed in this Agreement, the Privacy Guidelines
        are an integral part of this Agreement.
      </p>
      <p></p>
      <p>
        V. [User Code of Conduct]
      </p>
      <p></p>
      <p>
        5.1 You may use the Service to enable the peripheral microphone radio
        function. You must ensure that you own the intellectual property rights
        of the equipment and the content generated through the equipment or have
        been legally authorized to do so, and that any use of the Software and
        the Service by you does not infringe the legal rights of any third
        party.
      </p>
      <p></p>
      <p>
        5.2 You must not use the Service to engage in any of the following acts,
        including but not limited to.
      </p>
      <p></p>
      <p>
        5.2.1 post, transmit, distribute or store content that violates national
        laws, endangers national security and unity, social stability, public
        order and morality, social morality and content that is insulting,
        defamatory, obscene or contains any sexual or sexually suggestive or
        violent content
      </p>
      <p></p>
      <p>
        5.2.2 publishing, transmitting, disseminating, storing content that
        infringes on the legal rights of others such as reputation, portrait
        rights, intellectual property rights, trade secrets, etc.
      </p>
      <p></p>
      <p>
        5.2.3 publishing, transmitting, disseminating, or storing information
        that involves the privacy, personal information, property, or
        confidential information of others
      </p>
      <p></p>
      <p>
        5.2.4 publishing, transmitting, spreading harassment, advertising
        information and spam
      </p>
      <p></p>
      <p>
        5.2.5 without the written consent of Guangzhou HuaHui, the software or
        services are applied to commercial use or for profit activities.
      </p>
      <p></p>
      <p>
        5.2.6 falsify the facts, conceal the truth to mislead and deceive
        others.
      </p>
      <p></p>
      <p>
        5.2.7 other information that violates laws, regulations, policies and
        public order and morality, social morality or interferes with the normal
        operation of QQ Music and violates the legitimate rights and interests
        of other users or third parties.
      </p>
      <p></p>
      <p>
        5.3 You fully understand and agree that you are responsible for all of
        your own use of the Software and the Service, including any content you
        post and any consequences arising therefrom. You shall judge the content
        in the Service by yourself and bear all risks arising from the use of
        the content, including the risk arising from reliance on the
        correctness, completeness or usefulness of the content. Guangzhou HuaHui
        cannot and will not be liable for any loss or damage arising from the
        foregoing risks.
      </p>
      <p></p>
      <p>
        5.4 You agree that Guangzhou HuaHui may use the Content sent or
        transmitted by you for the purpose of performing this Agreement or
        providing the Service.
      </p>
      <p></p>
      <p>
        5.5 You should understand that you need to prepare your own terminal
        equipment (such as computers, mobile devices, etc.) in relation to the
        relevant services to use the Software and the Services and bear the
        necessary costs (such as network broadband charges, etc.).
      </p>
      <p></p>
      <p>
        5.6 When you use a particular service of the Software, there may be a
        separate agreement or business rules for that service (collectively, the
        &quot;Separate Agreement&quot;), and you should read and agree to the
        relevant Separate Agreement before using that service. If the service is
        provided by a third party, Guangzhou HuaHui and the third party shall
        each be responsible for any disputes that may arise within the scope of
        the legal provisions and agreements.
      </p>
      <p></p>
      <p>
        5.7 When using the Software and the Services, the User shall bear the
        following uncontrollable risk contents, including but not limited to.
      </p>
      <p></p>
      <p>
        5.7.1 The risk of loss, leakage, etc. of personal information that may
        be caused by irresistible factors.
      </p>
      <p></p>
      <p>
        5.7.2 the user must choose the version of the software that matches the
        equipment installed, otherwise, any problems or damages caused by the
        mismatch between the software and the equipment shall be borne by the
        user
      </p>
      <p></p>
      <p>
        5.8.3 the risks that may result from third party websites and related
        content when the User accesses third party websites using the Software
        shall be at the User&#39;s own risk
      </p>
      <p></p>
      <p>
        5.8.4 the risks and responsibilities that may arise from the
        dissemination of the content disseminated by the user through the
        Recording Assistant being forwarded and shared by others, and therefore
        such dissemination.
      </p>
      <p></p>
      <p>
        5.9 You shall comply with relevant local laws and regulations and
        respect local morals and customs in the course of using the Service. If
        your behavior violates local laws and regulations or moral customs, you
        shall be independently responsible for it.
      </p>
      <p></p>
      <p>
        5.10 You should avoid involving Guangzhou HuaHui in political and public
        events due to the use of the Service, otherwise Guangzhou HuaHui has the
        right to suspend or terminate the service to you.
      </p>
      <p></p>
      <p>
        VI. [Intellectual Property Rights Statement]
      </p>
      <p></p>
      <p>
        6.1 Guangzhou HuaHui is the owner of the intellectual property rights of
        the Software, except for the intellectual property rights of the
        advertisements involved in the Service, which are enjoyed by the
        corresponding advertisers, the intellectual property rights of the
        content provided by Guangzhou HuaHui in the Service (including but not
        limited to web pages, text, pictures, audio, video, graphics, etc.)
        belong to Guangzhou HuaHui.
      </p>
      <p></p>
      <p>
        6.2 Unless otherwise specifically stated, the copyrights, patents and
        other intellectual property rights of the software relied upon by
        Guangzhou HuaHui in providing the Service shall be owned by Guangzhou
        HuaHui.
      </p>
      <p></p>
      <p>
        6.3 The copyright or trademark right of the graphics and business logos
        used by Guangzhou HuaHui in the Service shall belong to Guangzhou
        HuaHui. Without the prior written consent of Guangzhou HuaHui, the User
        shall not display or use the above logos in any manner or otherwise deal
        with them, nor shall the User indicate to others that the User has the
        right to display, use, or otherwise have the right to deal with the
        Guangzhou HuaHui logos.
      </p>
      <p></p>
      <p>
        6.4 The above and any other intellectual property rights owned by
        Guangzhou HuaHui or relevant advertisers in accordance with the law are
        protected by law, and without the written permission of Guangzhou HuaHui
        or relevant advertisers, the User shall not use or create relevant
        derivative works in any form.
      </p>
      <p></p>
      <p>
        6.5 The user publishes audio and performance information when using the
        Transvoice Assistant service, the intellectual property rights of this
        information belong to the user, and the responsibility is borne by the
        user, but the user&#39;s publication and uploading behavior is the
        authorization of Guangzhou HuaHui Company, and the user confirms that
        the information uploaded and published by the user is non-exclusive and
        permanently authorized to Guangzhou HuaHui Company to use, copy,
        transmit, modify, compile, adapt, sub-license, translate, create
        derivative works worldwide. The user acknowledges that by uploading or
        posting the information, the user grants Guangzhou HuaHui a
        non-exclusive, perpetual license to use, reproduce, transmit, modify,
        compile, adapt, sublicense, translate, create derivative works, publish,
        perform and display the content worldwide.
      </p>
      <p></p>
      <p></p>
      <p>
        VII. [Legal Liability]
      </p>
      <p></p>
      <p>
        7.1 If Guangzhou HuaHui company found or received reports or complaints
        from others that the information content or behavior of the user release
        violates the agreement, Guangzhou HuaHui company has the right to make
        independent judgment on the relevant content at any time without notice,
        and take technical measures to delete, block or disconnect the link, and
        depending on the severity of the case, including but not limited to
        suspension or termination of the service, penalties (the agreement
        penalties include but not (including but not limited to warning,
        function blocking, equipment blocking, etc.), legal responsibility and
        other measures.
      </p>
      <p></p>
      <p>
        7.2 The user understands and agrees that Guangzhou HuaHui has the right
        to punish the violation of relevant laws and regulations or the
        provisions of this Agreement in accordance with reasonable judgment, to
        take appropriate legal action against any user who violates the law, and
        to provide the user&#39;s personal information in accordance with the
        provisions of the law or the requirements of the relevant government
        agencies, security organs, judicial organs, save the relevant
        information to report to the relevant departments, etc., the user shall
        bear the resulting The user shall bear all legal responsibilities
        arising therefrom.
      </p>
      <p></p>
      <p>
        7.3 The user understands and agrees that the user shall indemnify and
        hold harmless Guangzhou HuaHui and its partner companies and affiliates
        from any claims, demands or losses, including reasonable attorneys&#39;
        fees, asserted by any third party as a result of or arising from the
        user&#39;s violation of this Agreement.
      </p>
      <p></p>
      <p>
        VIII. [Force Majeure and Other Exemptions]
      </p>
      <p></p>
      <p>
        8.1 In the course of using the Service, risk factors such as force
        majeure may be encountered, causing interruption of the Service. Force
        majeure refers to objective events that cannot be foreseen, overcome and
        avoided and have a significant impact on one or both parties, including
        but not limited to natural disasters such as floods, earthquakes, plague
        epidemics and storms, as well as social events such as war, unrest,
        government actions, etc. In the event of the above, Guangzhou HuaHui
        will endeavor to cooperate with the relevant units in the first instance
        to repair in a timely manner, but the losses caused to you by this
        Guangzhou HuaHui is exempt from liability to the extent permitted by
        law.
      </p>
      <p></p>
      <p>
        8.2 You understand and agree that the Service is provided in accordance
        with the current status of existing technology and conditions. Guangzhou
        HuaHui company will do its best to provide services to you to ensure the
        consistency and security of the service; but Guangzhou HuaHui company
        can not always foresee and prevent legal, technical and other risks,
        including but not limited to force majeure, viruses, Trojan horses,
        hacker attacks, system instability, third-party service defects,
        government actions and other possible causes of service interruption,
        data loss and other losses and risks.
      </p>
      <p></p>
      <p>
        8.3 You understand and agree that the services provided to you by
        Guangzhou HuaHui may include the relevant services provided by Guangzhou
        HuaHui to the user with the authorization of the legal right holder,
        therefore, due to the constraints of the time, scope and restrictions of
        the authorization of Guangzhou HuaHui, Guangzhou HuaHui will open the
        relevant services to the area where the user is located and the
        user&#39;s specific use of the service time, geographical scope, the
        Terminal equipment, etc. to do certain restrictions, may lead to the
        user in a certain area or time, different terminal equipment, etc. can
        not get the relevant services, including but not limited to the current
        relevant services only in mainland China (excluding Hong Kong, Macao,
        Taiwan and other regions) to open and use the relevant services, if you
        are outside mainland China or use the non-mainland China network /
        equipment If you are outside of mainland China or using a non-mainland
        China network/device, you will not be able to use/access the relevant
        services. For the aforementioned reasons or other reasons for the user
        can not enjoy the relevant services, Guangzhou HuaHui company does not
        have to bear any responsibility.
      </p>
      <p></p>
      <p>
        8.4 You understand and agree that the services or content provided to
        you by Guangzhou HuaHui may be changed or interrupted, suspended or
        terminated in accordance with changes in laws, regulations or regulatory
        policies, the requirements of rights holders or copyright holders, the
        requirements of law enforcement departments or judicial organs,
        complaints from other third parties, etc. For the aforementioned reasons
        or other reasons caused by the user can not enjoy the relevant services
        or content, Guangzhou HuaHui company does not need to assume any
        responsibility.
      </p>
      <p></p>
      <p>
        8.5 You understand and agree that in the process of using the Service,
        you may encounter risks arising from network information or other user
        behavior, and Guangzhou HuaHui shall not be responsible for the
        authenticity, applicability or legality of any information, nor shall it
        be responsible for the damage caused to you by infringement. These risks
        include, but are not limited to.
      </p>
      <p></p>
      <p>
        8.5.1 Any psychological, physical harm and economic loss caused or
        likely to be caused by the use of the services under this Agreement,
        suffering from misleading, deceptive or otherwise caused by others.
      </p>
      <p></p>
      <p>
        8.5.2 other risks caused by network information or user behavior.
      </p>
      <p></p>
      <p>
        8.6 Guangzhou HuaHui company in accordance with this agreement to obtain
        the right to deal with illegal content, the right does not constitute an
        obligation or commitment of Guangzhou HuaHui company, Guangzhou HuaHui
        company can not guarantee the timely detection of violations or the
        corresponding treatment.
      </p>
      <p></p>
      <p>
        8.7 In any case, you should not trust the borrowing, demand for password
        or other network information involving property. Involved in property
        operations, please be sure to verify the identity of the other party
        first, and please often pay attention to the tips of Guangzhou HuaHui
        company about the prevention of fraudulent crime.
      </p>
      <p></p>
      <p>
        Ⅸ , [service changes, interruptions, termination]
      </p>
      <p></p>
      <p>
        9.1 Guangzhou HuaHui company is always changing and improving the
        service. Guangzhou HuaHui company according to their own business
        decisions, government action, force majeure and other reasons may choose
        to suspend, interrupt and terminate part or all of the services of the
        voice picker assistant. If such circumstances occur, Guangzhou HuaHui
        will take the form of announcement to notify the user, but does not bear
        any losses caused to the user.
      </p>
      <p></p>
      <p>
        9.2 If any of the following circumstances occur, Guangzhou HuaHui shall
        have the right to interrupt or terminate the services provided to you
        without notice.
      </p>
      <p></p>
      <p>
        9.2.1 You violate the relevant laws and regulations or the agreement of
        this Agreement.
      </p>
      <p></p>
      <p>
        9.2.2 In accordance with the provisions of the law or the requirements
        of the competent authorities.
      </p>
      <p></p>
      <p>
        9.2.3 for security reasons or other necessary circumstances.
      </p>
      <p></p>
      <p>
        X. [Effective and Change of Agreement]
      </p>
      <p></p>
      <p>
        10.1 You use the Recording Assistant software or services that you have
        read this agreement and accept the binding of this agreement.
      </p>
      <p></p>
      <p>
        10.2 Guangzhou HuaHui company has the right to modify the terms of this
        agreement when necessary. You can check the latest version of the terms
        and conditions of the agreement on the relevant service page and
        consciously comply with the content of the terms of this agreement.
      </p>
      <p></p>
      <p>
        10.3 After the change of the terms of this agreement, if you continue to
        use the service, it is deemed that you have accepted the modified
        agreement. If you do not accept the modified agreement, you should stop
        using the Service.
      </p>
      <p></p>
      <p>
        XI. [Jurisdiction and Application of Law]
      </p>
      <p></p>
      <p>
        11.1 This Agreement is entered into in Haizhu District, Guangzhou City,
        Guangdong Province, People&#39;s Republic of China.
      </p>
      <p></p>
      <p>
        11.2 The establishment, entry into force, performance, interpretation
        and dispute resolution of this Agreement, the laws of the People&#39;s
        Republic of China mainland (excluding conflict of laws) shall apply.
      </p>
      <p></p>
      <p>
        11.3 If any dispute or controversy arises between you and Guangzhou
        HuaHui, it shall first be resolved through friendly consultation; if
        consultation fails, you agree to submit the dispute or controversy to
        the jurisdiction of the people&#39;s court of the place where this
        Agreement is signed.
      </p>
      <p></p>
      <p>
        XII. [Terms of use for minors]
      </p>
      <p></p>
      <p>
        12.1 If the User is under 18 years of age, the User is a minor and shall
        read this Agreement and use the Service under the supervision and
        guidance of a guardian.
      </p>
      <p></p>
      <p>
        12.2 Minor users are not yet involved in the world, easily confused by
        the virtual image of the Internet, and are curious, lacking the ability
        to deal with improvisation in case of problems, and easily used by
        people with ulterior motives and lacking the ability to protect
        themselves. Therefore, minor users should pay attention to the following
        matters when using the Services to raise their safety awareness and
        strengthen self-protection.
      </p>
      <p></p>
      <p>
        12.2.1 Recognize the difference between the online world and the real
        world to avoid getting addicted to the Internet and affecting their
        daily study life.
      </p>
      <p></p>
      <p>
        12.2.2 To strengthen the awareness of personal protection when filling
        in personal data to avoid harassment of personal life by undesirable
        elements.
      </p>
      <p></p>
      <p>
        13.2.3 To learn to use the Internet correctly under the guidance of a
        guardian or teacher.
      </p>
      <p></p>
      <p>
        12.2.4 Avoid meeting randomly with unfamiliar Internet friends or
        participating in association activities so that unruly elements will not
        take advantage of them and endanger their own safety.
      </p>
      <p></p>
      <p>
        12.3 Guardians and schools should provide more guidance to minors in
        using the Service. In particular, parents should care about the growth
        of their children, pay attention to communication with their children,
        and instruct them on the safety issues they should pay attention to when
        using the Internet to prevent problems before they occur. If guardians
        or parents lack effective ways to supervise minors&#39; online behavior,
        they can strengthen the supervision of minors&#39; online behavior and
        guide minors to use the Service in a healthy and harmonious way.
      </p>
      <p></p>
      <p>
        12.4 The minor reads this Agreement and uses the Service without the
        guardian&#39;s supervision or guidance or the guardian&#39;s failure to
        fulfill the guardianship or guidance obligations, and any adverse
        consequences or losses brought to the minor or his family or others
        shall be borne by the minor and his parents or guardians, and Guangzhou
        HuaHui shall not bear any responsibility for the consequences or losses
        caused by the minor&#39;s behavior.
      </p>
      <p></p>
      <p>
        XIII. [Others]
      </p>
      <p>
        13.1 The headings of all provisions of this Agreement are for
        convenience only and have no actual meaning of their own and shall not
        be relied upon for the interpretation of the meaning of this Agreement.
      </p>
      <p></p>
      <p>
        13.2 The terms of this Agreement are partially invalid or unenforceable
        for whatever reason, the remaining terms remain valid and binding on
        both parties.
      </p>
      <p></p>
      <p>
        13.3 If you have comments or suggestions on this agreement or the
        service, you can contact Guangzhou HuaHui customer service department,
        we will give you the necessary help.
      </p>
      <p></p>
      <p style="text-align:right">
        Guangzhou HuaHui Information Technology Co.
      </p>
      <p>
        <br />
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "agreement",
  data() {
    return {
      lang: "",
    };
  },
  mounted() {
    let { lang } = this.$route.params;
    this.lang = lang;
    switch (lang) {
      case "EN":
        document.title = "Privacy Policy";
        break;
      default:
        document.title = "隐私政策-拾音助手";
    }
  },
};
</script>

<style scoped>
.box {
  min-height: 100vh;
  border: 1px solid #f0f0f0;
  padding: 10px 16px 20px;
  word-break: break-word;
  line-height: 1.6;
  color: #121212;
  box-sizing: border-box;
  text-align: left;
}
.box h1,
.box h2 {
  clear: left;
  margin-top: 1em;
  margin-bottom: 1.16667em;
  font-size: 36px;
  line-height: 1.5;
  font-weight: 600;
  text-align: center;
}
.box h2 {
  font-size: 30px;
  text-align: left;
}
.box p {
  margin: 1.4em 0;
  font-size: 28px;
}
</style>
